// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';
/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

function check() {
  if (
    confirm(
      'You are about to leave the New Hampshire Federal Credit Union website. New Hampshire Federal Credit Union does not own or operate the linked website and is not responsible for the content of the website. New Hampshire Federal Credit Union does not make any guarantees or endorsements of products that may be available on the linked website. New Hampshire Federal Credit Union does not represent either you or the other party if you enter into a transaction on the website. Privacy and security policies may differ from those practiced by New Hampshire Federal Credit Union.'
    )
  ) {
    return true;
  } else return false;
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  $(document).on('click', '.icon-links__link', function () {
    let calcName = $(this).attr('href').replace('#', '');
    window.open('calc/?calc=' + calcName, '', 'width=750,height=700');
  });

  // Disable click on menu links
  $('#menu-main-menu, #menu-secondary-menu-1').on('click', function (e) {
    if ($(this).attr('href').indexOf('#') == 0) {
      console.log(1);
      e.preventDefault();
    }
  });

  /* global ajax_object */
  /* global excl_object */

  var url = ajax_object.site_url;
  let excl = excl_object.url;
  $(document).on('click', 'a', function (e) {
    if (
      $(this).attr('href').indexOf('http') == 0 &&
      $(this).attr('href').indexOf(url) == -1 &&
      $(this).attr('href').indexOf('maps') == -1 &&
      excl.indexOf($(this).attr('href')) == -1
    ) {
      if (!check()) {
        e.preventDefault();
      }
    }
  });

  // $('a')
  //   .filter(function () {
  //     return this.hostname && this.hostname !== location.hostname;
  //   })
  //   .click(function (e) {
  //     if (!check()) {
  //       e.preventDefault();
  //     }
  //   });

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  $('.popup-button').on('click', function () {
    $('#popup').toggleClass('show');
  });

  $('.link-article').on('click', function () {
    $('.second_block').addClass('show');
    if ($('.second_block').hasClass('show')) {
      $('.link-article').css('display', 'none');
    }
  });

  $('.search-click').on('click', function () {
    $('.search-form').addClass('show');

    $(document).mouseup(function (e) {
      var div = $('.search-form');
      if (!div.is(e.target) && div.has(e.target).length === 0) {
        div.removeClass('show');
      }
    });
  });

  // var currentWindowUrl = window.location.href;
  // $('.widget_nav_menu .menu-item a').each(function () {
  //   if (currentWindowUrl === $(this).attr('href')) {
  //     $(this).closest('.menu-item').addClass('active');
  //   }
  // });

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /* global gform */

  gform.addFilter(
    'gform_datepicker_options_pre_init',
    function (optionsObj, formId, fieldId) {
      // Apply to field 2 only
      if (fieldId == 22 && formId == 3) {
        optionsObj.maxDate = 0;
      }
      return optionsObj;
    }
  );

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();

  $('.posts-slider').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
  /**
   * add ajax for single-video template
   */
  $('.single-video__video').on('click', function (e) {
    e.preventDefault();
    // eslint-disable-next-line no-undef
    let elements = $('#single-video__video');
    let id = elements.data('post-id');
    let $this = $(this);
    if ($this.data('requestRunning')) {
      return;
    }
    $this.data('requestRunning', true);
    $.ajax({
      // eslint-disable-next-line no-undef
      url: ajax_object.ajax_url,
      type: 'POST',
      data: {
        action: 'get_video',
        id: id,
      },
      beforeSend: function () {
        $this.addClass('loading');
      },
      success: function (response) {
        $this.removeClass('loading');
        elements.empty();
        elements.append(response.iframe);
      },
      complete: function () {
        $this.data('requestRunning', false);
      },
      error: function (err) {
        console.log(err);
      },
    });
  });
});
function ajaxFilterPost() {
  let title = $('#title-input').val();
  let select = $('#taxonomy-select').val();
  let postContainer = $('#section-video__list');
  let id = postContainer.data('post-id');
  let $this = $(this);
  if ($this.data('requestRunning')) {
    return;
  }
  $this.data('requestRunning', true);
  $.ajax({
    // eslint-disable-next-line no-undef
    url: ajax_object.ajax_url,
    type: 'POST',
    data: {
      action: 'get_post_videos',
      input: title,
      select: select,
      id: id,
    },
    beforeSend: function () {
      postContainer.addClass('loading');
    },
    success: function (response) {
      postContainer.removeClass('loading');
      postContainer.empty();
      postContainer.append(response.result);
    },
    complete: function () {
      $this.data('requestRunning', false);
    },
    error: function (err) {
      console.log(err);
    },
  });
}
$('#title-input').on('input', function () {
  clearTimeout($(this).data('timer'));
  $(this).data(
    'timer',
    setTimeout(function () {
      ajaxFilterPost();
    }, 700)
  );
});
$('#taxonomy-select').on('change', function () {
  ajaxFilterPost();
  $('#page-video__title').empty();
  $('#page-video__title').append(
    $('#taxonomy-select').find('option:selected').text()
  );
});
/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
